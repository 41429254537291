import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiConfigService } from 'src/app/services/config/api-config.service';
import { ArrayService } from 'src/app/services/utils/array.service';
import { MenuItemResponsePage, MenuItemService, MenuSectionService, MenuSectionUpdateRequest, SchemaOrgMenu, SchemaOrgMenuItem, SchemaOrgMenuSection } from 'src/generated-sources/business-openapi';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  standalone: false,
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {

  @Input() menu: SchemaOrgMenu;
  @Input() businessId: number;
  selectedCategory: any;
  categoryInput: any;
  loadingSections: number[] = [];
  @Output() editMenuItem: EventEmitter<SchemaOrgMenuItem> = new EventEmitter<SchemaOrgMenuItem>();

  constructor(
    private arrayService: ArrayService,
    private apiConfigService: ApiConfigService,
    private menuSectionService: MenuSectionService,
    private menuItemService: MenuItemService
  ) {
    this.menuSectionService.configuration = this.apiConfigService.getBusinessApiConfig();
    this.menuItemService.configuration = this.apiConfigService.getBusinessApiConfig();
  }

  ngOnInit() {
  }

  onAccordionChange(event: any, section: SchemaOrgMenuSection) {
    this.loadingSections.push(section.id);
    if (event.detail.value == undefined || !event.detail.value.includes('ion-accordion')) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      return
    }
    section.hasMenuItem = []
    if (event.detail.value) {
      this.menuItemService.getMenuItemsForMenuSection(this.businessId, this.menu.id, section.id).subscribe((menuItems: MenuItemResponsePage) => {
        console.log(menuItems)
        console.log('Menu items:', menuItems.content);
        //Find the this.menu.hasMenuSection with section.id and add menuItems to it
        section.hasMenuItem = menuItems.content
        this.loadingSections = this.loadingSections.filter(item => item !== section.id);
      })
    }
  }

  onReorderSections(event: any) {
    console.log("ReorderSections");
    const itemMoved = this.menu.hasMenuSection.splice(event.detail.from, 1)[0];
    this.menu.hasMenuSection.splice(event.detail.to, 0, itemMoved);

    // Complete the reorder process and notify
    event.detail.complete();

    // Optionally send the new order to the server or update your backend
    console.log('Updated menu order:', this.menu.hasMenuSection);
    this.saveOrderOfMenuElements();
  }

  saveOrderOfMenuElements() {
    let i = 0;
    let request: Array<MenuSectionUpdateRequest> = []
    for (let section of this.menu.hasMenuSection) {
      //section.sort = i
      let menuSectionUpdate = {
        id: section.id,
        name: section.name,
        status: 'PUBLISHED',
        sort: i
      } as MenuSectionUpdateRequest
      i++
      request.push(menuSectionUpdate)
    }
    this.menuSectionService.updateMenuSection(this.businessId, this.menu.id, request).subscribe((menuSectionResponseList) => {
      console.log('Menu sections reordered:', menuSectionResponseList);
      //this.toastService.showSuccess(this.translateService.instant("EDIT_MENU.IS_REORDERED", { menuName: "Menu" }))
    }, error => {
      console.error('Error while saving order of menu elements:', error);
      //this.errorAlertService.presentUnexpectedErrorAlert(error)
    });
  }
  editCategory(event: any, section: any) {
    console.log('Editing category:', section);
    event.stopPropagation();
    // Here you can either open a modal or navigate to an edit page to update the category
    // Example: Open a modal or a new page to edit the category
    // this.openEditCategoryModal(section);

    // Or if you want to show an input form for inline editing:
    this.selectedCategory = section; // Assuming you're setting the category to be edited
    this.categoryInput = section.name; // Or set other properties as needed
    console.log('Selected category:', this.selectedCategory);
  }

  saveCategory(section: SchemaOrgMenuSection, index: number) {
    console.log('Gespeicherte Kategorie:', section);
    console.log("categoryInput", this.categoryInput);
    let menuSectionUpdate = {
      id: section.id,
      name: this.categoryInput,
      status: 'PUBLISHED',
      sort: index
    } as MenuSectionUpdateRequest
    console.log('menuSectionUpdate:', menuSectionUpdate);
    this.menuSectionService.updateMenuSection(this.businessId, this.menu.id, [menuSectionUpdate]).subscribe((menuSectionResponseList) => {
      this.selectedCategory = null;
      section.name = this.categoryInput;
      console.log('Menu section edited"', menuSectionResponseList);
    }, error => {
      console.error('Error while saving order of menu elements:', error);

    });
  }

  cancelEdit() {
    this.selectedCategory = null;  // Stop editing mode and revert any changes
  }

  onMenuItemEditing(item: SchemaOrgMenuItem) {
    this.editMenuItem.emit(item);
  }

  deleteCategory(section: SchemaOrgMenuSection) { }



}
