import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from '../pipes/price.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CategoriesModule } from '../business-owner/menu/menu-editor/categories/categories.module';
import { ItemsModule } from '../business-owner/menu/menu-editor/items/items.modules';
import { EditItemModule } from '../business-owner/menu/menu-editor/edit-item/edit-item.modules';
import { BillEntryModule } from '../public/ordering/bill/bill-entry/bill-entry.module';
import { SharedPriceModule } from './shared-price.module';
import { SharedTranslateModule } from './shared-translate.module';
import { SingleOrderModule } from '../business-operator/business/business-operator/modals/order-overwiew-modal/single-order/single-order.module';
import { SingleOrderComponent } from '../business-operator/business/business-operator/modals/order-overwiew-modal/single-order/single-order.component';



@NgModule({
  declarations: [
    //PricePipe,
    //BillEntryComponent
  ],
  imports: [
    CommonModule,
    CategoriesModule,
    ItemsModule,
    EditItemModule,
    SingleOrderModule,
    BillEntryModule,
    SharedPriceModule,
    SharedTranslateModule
  ],
  exports: [
    //PricePipe,
    //TranslateModule,
    SharedTranslateModule,
    SingleOrderComponent
  ]
})
export class SharedModule { }
