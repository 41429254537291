import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DetailedOrderItemResponse, DetailedOrderResponse } from 'src/generated-sources/business-openapi';

@Component({
  selector: 'app-single-order',
  templateUrl: './single-order.component.html',
  standalone: false,
  styleUrls: ['./single-order.component.scss'],
})
export class SingleOrderComponent implements OnInit {
  @Input() order: DetailedOrderResponse
  @Input() isSelected: boolean
  @Input() status: string = ''
  @Input() isCheckBoxActivated: boolean = true
  @Output() selectionChanged = new EventEmitter<boolean>();

  skipTrigger = false;

  constructor() {

  }

  ngOnInit() {
    console.log(this.order)
    console.log(this.isSelected)
  }

  calculateTotalItemPrice(item: DetailedOrderItemResponse) {
    let price = item.price.amount * item.quantity
    item.selectedOptions.forEach(option => {
      price += item.quantity * option.price
    })
    return price
  }

  toggleSelection() {
    if (this.skipTrigger) {
      this.skipTrigger = false;
      return;
    }
    this.selectionChanged.emit(!this.isSelected); // Emit the new selection state
  }

  triggerSelectAll(selectAll: boolean) {
    this.skipTrigger = true;
    this.isSelected = selectAll
  }
}

