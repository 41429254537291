import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleOrderComponent } from './single-order.component';  // Your component
import { SharedModule } from 'src/app/shared/shared.module'; // Import SharedModule, which exports PricePipe
import { SharedPriceModule } from 'src/app/shared/shared-price.module';
import { IonicModule } from '@ionic/angular';
import { SharedTranslateModule } from 'src/app/shared/shared-translate.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        SingleOrderComponent,
    ],
    imports: [
        CommonModule,
        //SharedModule,
        IonicModule,
        TranslateModule.forChild(),
        SharedPriceModule,
        //SharedTranslateModule
    ],
    exports: [
        SingleOrderComponent,
    ]
})
export class SingleOrderModule { }
